define("discourse/plugins/Toolbar Code Fences Buttons (cfbtn)/discourse/initializers/cfbtn", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor"], function (_exports, _pluginApi, _dEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function addButtons(siteSettings) {
    if (siteSettings.cfbtn_enabled) {
      //openHAB items syntax button
      if (siteSettings.cfbtn_openhab_items) {
        (0, _dEditor.onToolbarCreate)(toolbar => {
          toolbar.addButton({
            id: "cfbtn_openhab_items",
            group: "extras",
            icon: "far-file-alt",
            perform: e => e.applySurround('\n```csv\n', '\n```\n', 'cfbtn_code_default_text', {
              multiline: false
            })
          });
        });
      }
      //openHAB rules syntax button
      if (siteSettings.cfbtn_openhab_rules) {
        (0, _dEditor.onToolbarCreate)(toolbar => {
          toolbar.addButton({
            id: "cfbtn_openhab_rules",
            group: "extras",
            icon: "far-file-code",
            perform: e => e.applySurround('\n```php\n', '\n```\n', 'cfbtn_code_default_text', {
              multiline: false
            })
          });
        });
      }
      //openHAB sitemap syntax button
      if (siteSettings.cfbtn_openhab_sitemap) {
        (0, _dEditor.onToolbarCreate)(toolbar => {
          toolbar.addButton({
            id: "cfbtn_openhab_sitemap",
            group: "extras",
            icon: "far-file-image",
            perform: e => e.applySurround('\n```php\n', '\n```\n', 'cfbtn_code_default_text', {
              multiline: false
            })
          });
        });
      }
      //javascript
      if (siteSettings.cfbtn_javascript) {
        (0, _dEditor.onToolbarCreate)(toolbar => {
          toolbar.addButton({
            id: "cfbtn_javascript",
            group: "extras",
            icon: "far-file-code",
            perform: e => e.applySurround('\n```javascript\n', '\n```\n', 'cfbtn_code_default_text', {
              multiline: false
            })
          });
        });
      }
      //custom syntax, defined in discourse settings dialog
      if (siteSettings.cfbtn_custom1.length !== 0) {
        var syntax = siteSettings.cfbtn_custom1;
        (0, _dEditor.onToolbarCreate)(toolbar => {
          toolbar.addButton({
            id: "cfbtn_custom1",
            group: "extras",
            icon: "far-file-code",
            perform: e => e.applySurround('\n```' + syntax + '\n', '\n```\n', 'cfbtn_code_default_text', {
              multiline: false
            })
          });
        });
      }
    }
  }
  function priorToApi(container) {
    const siteSettings = container.lookup('site-settings:main');
    addButtons(siteSettings);
  }
  function initializePlugin(api) {
    const siteSettings = api.container.lookup('site-settings:main');
    addButtons(siteSettings);
  }
  var _default = _exports.default = {
    name: 'cfbtn',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', api => initializePlugin(api), {
        noApi: () => priorToApi(container)
      });
    }
  };
});